import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Typography, Grid, Button,
} from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { useTranslation } from 'next-i18next';
import 'react-multi-carousel/lib/styles.css';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import {
  StyledButtonBase,
  StyledCarousel,
} from 'views/common/components/UI/TourCard/TourCard.styles';
import ERPlaceholder from 'public/imgs/default/er_default.png';
import { ImgBox, Typography700 } from 'views/common/components';
import { TOUR_TYPES } from 'lib/utils/CONSTANTS';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';

let isMoving = false;
const TourCard = (props) => {
  const { t } = useTranslation('fe_er_common_tour_card');
  const {
    name,
    images,
    tourType,
    startLocationName,
    daysCount,
    tourSavings,
    startingPrice,
    tourPath,
    className,
    nameTypoComponent = 'h5',
    tourTypeIdentifier,
    tagline,
  } = props;

  const { deviceType, isSSR } = useSelector((state) => state.globalData);
  return (
    <StyledButtonBase
      className={`TCcardBtn ${className}`}
      onClick={(e) => {
        if (isMoving) {
          e.preventDefault();
        }
      }}>
      <Box className="TCtourCard" component={NavigatorLink} href={tourPath}>
        <Box className="TCtourImgBox">
          {images.length > 0 ? (
            <StyledCarousel
              beforeChange={() => {
                isMoving = true;
              }}
              afterChange={() => {
                isMoving = false;
              }}
              responsive={{
                all: {
                  breakpoint: { max: 4000, min: 0 },
                  items: 1,
                },
              }}
              arrows
              ssr={isSSR}
              deviceType={deviceType}
              autoPlay={false}
              shouldResetAutoplay={false}>
              {images.map((img) => (
                <Box className="TCcaruselItem">
                  <ImgBox
                    classes="mrc-imgBox"
                    image={{ src: img.image_url, alt: img.alt }}
                    imgHeight="100%"
                    data-srcset={`${img.small_url} 320w, ${img.medium_url} 600w, ${img.large_url} 1024w`}
                    priority={img.priority}
                  />
                </Box>
              ))}
            </StyledCarousel>
          ) : (
            <ImgBox
              classes="TCtourImg"
              image={{ src: ERPlaceholder, alt: name }}
              imgHeight="100%"
            />
          )}
        </Box>
        <Box className="TCtourDetails">
          <Box>
            <Typography700
              variant="h5"
              component={nameTypoComponent}
              color="secondary">
              {name}
            </Typography700>
            <Typography variant="body2" component="p" className="TCTourType">
              {tourType}
            </Typography>
          </Box>
          <Box>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={1}
              className="TCtourInfo">
              <Grid item className="TCtourLocationDays">
                <Typography variant="caption" component="p">
                  <RoomIcon />
                  {startLocationName}
                </Typography>
                <Typography variant="caption" component="p">
                  {tourTypeIdentifier !== TOUR_TYPES.GUIDED_OFFROAD ? (
                    <>
                      {daysCount && (
                        <>
                          <EventNoteIcon />
                          {`${daysCount} ${t(
                            'fe_er_common_tour_card:activity_day',
                          )}/ `}
                          {`${daysCount - 1} ${t(
                            'fe_er_common_tour_card:nights',
                          )}`}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <AccessTimeIcon />
                      {tagline}
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item align="right" mt={-0.25}>
                {startingPrice && (
                  <>
                    <Typography
                      variant="caption"
                      component="p"
                      display="block"
                      className="TCligthFontWeight">
                      {t('fe_er_common_tour_card:starting_at')}
                    </Typography>
                    <Typography500
                      variant="h5"
                      component="span"
                      className="TCPrice">
                      {startingPrice}
                    </Typography500>
                  </>
                )}
              </Grid>
            </Grid>

            <Button
              color="primary"
              disableElevation
              variant="contained"
              fullWidth
              component={NavigatorLink}
              href={tourPath}>
              {t('fe_er_common_tour_card:view_tour')}
            </Button>
            {tourSavings && (
              <Typography
                variant="caption"
                component="p"
                className="TCSavedValue"
                mt={1.5}>
                {t('fe_er_common_tour_card:save_up')}
                {tourSavings}
                {t('fe_er_common_tour_card:per_person')}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </StyledButtonBase>
  );
};
export { TourCard };
