import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  textAlign: 'center',
  color: theme.palette.secondary.dark,
  overflow: 'hidden',
}));
