import React from 'react';
import {
  Typography, Grid, Button, Box,
} from '@mui/material';
import { ImgBox, Typography700 } from 'views/common/components';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import StyledNavigatorLink from 'views/common/components/UI/DealCard/DealCard.styles';

const DealCard = (props) => {
  const { t } = useTranslation('fe_er_deal_card');
  const {
    title,
    image,
    dealValue,
    url,
    oldValue,
    bookBefore,
    pickupBetween,
    dropoffBy,
    participatingLocations,
    availableForAllPickupLocations,
    showDates,
    featured,
    tagline,
    featuredDealCardInfo = {},
  } = props;
  const getParticpateLoactionsSlice = () => (
    <span className="rideDetailsValue">
      {participatingLocations.slice(0, 3).map((location, index) => (
        <Typography key={location.id} component="span" className="dealLocation">
          {location.name}
          {index === 2 || index === participatingLocations.length - 1
            ? ' '
            : ', '}
        </Typography>
      ))}
      {participatingLocations.length > 3 && (
        <Typography component="span" className="dealLocation">
          {`(+${participatingLocations.length - 3} ${t(
            'fe_er_deal_card:more',
          )})`}
        </Typography>
      )}
    </span>
  );
  return (
    <StyledNavigatorLink className="root" href={url}>
      {(showDates || bookBefore || pickupBetween || dropoffBy) && (
        <Box className="DCfeatured">
          {featured && (
            <Typography500
              variant="caption"
              component="p"
              className="DCfeaturedText">
              {t('fe_er_deal_card:featured')}
            </Typography500>
          )}
        </Box>
      )}
      {Object.keys(featuredDealCardInfo).length === 0 && (
        <Box className="title">
          <Typography700 variant="h5" component="p">
            {title}
          </Typography700>
        </Box>
      )}
      <Box className="DCImgContainer">
        <ImgBox image={image} imgHeight="100%" />
        {Object.keys(featuredDealCardInfo).length > 0 && (
          <>
            <Box className="DCImgMask" />
            <Box className="DCfeaturedInfo">
              {tagline && (
                <Typography
                  variant="caption"
                  component="p"
                  className="DCSaveUpText">
                  {tagline}
                </Typography>
              )}
              <Typography
                variant="caption"
                className="DCSaveText"
                dangerouslySetInnerHTML={{
                  __html: featuredDealCardInfo.caption,
                }}
              />
              <Typography700
                component="p"
                variant="h2"
                className="DCSavedValue"
                dangerouslySetInnerHTML={{
                  __html: dealValue,
                }}
              />
              <Typography500 variant="h5">
                {featuredDealCardInfo.desc}
              </Typography500>
            </Box>
          </>
        )}
      </Box>
      {Object.keys(featuredDealCardInfo).length > 0 && (
        <Box className="title DCfeaturedTitle">
          <Typography700 variant="h5" component="p">
            {title}
          </Typography700>
        </Box>
      )}
      <Box className="infoWrapper">
        <Box>
          {(showDates || bookBefore) && (
            <Box mt={2}>
              <Typography700
                variant="caption"
                component="p"
                className="rideDetails">
                <Typography variant="caption" className="rideDetailsLabel">
                  {t('fe_er_deal_card:book_before')}
                  :
                </Typography>
                <Typography700
                  component="span"
                  variant="caption"
                  className="rideDetailsValue">
                  {bookBefore}
                </Typography700>
              </Typography700>
            </Box>
          )}
          {(showDates || pickupBetween) && (
            <Typography700
              variant="caption"
              component="p"
              className="rideDetails">
              <Typography variant="caption" className="rideDetailsLabel">
                {t('fe_er_deal_card:pickup_between')}
                :
              </Typography>
              <Typography700 variant="caption" className="rideDetailsValue">
                {pickupBetween}
              </Typography700>
            </Typography700>
          )}
          {(showDates || dropoffBy) && (
            <Typography700
              variant="caption"
              component="p"
              className="rideDetails">
              <Typography variant="caption" className="rideDetailsLabel">
                {t('fe_er_deal_card:dropoff_by')}
                :
              </Typography>
              <Typography700 variant="caption" className="rideDetailsValue">
                {dropoffBy}
              </Typography700>
            </Typography700>
          )}
          {(participatingLocations || availableForAllPickupLocations) && (
            <Typography700
              variant="caption"
              component="p"
              className="rideDetails">
              <Typography variant="caption" className="rideDetailsLabel">
                {t('fe_er_deal_card:participating_locations')}
                :
              </Typography>
              {availableForAllPickupLocations ? (
                <Typography className="rideDetailsValue dealLocation">
                  {t('fe_er_deal_card:available_for_all_pickup_location')}
                </Typography>
              ) : (
                getParticpateLoactionsSlice()
              )}
            </Typography700>
          )}
        </Box>
        <Grid container className="container">
          <Grid item xs={6}>
            {oldValue && (
              <Box mb={1}>
                <Typography500
                  className="oldValue"
                  variant="h6"
                  component="span"
                  dangerouslySetInnerHTML={{ __html: oldValue }}
                />
              </Box>
            )}
            <Box>
              <Typography500
                className="dealValue"
                variant="body1"
                component="span"
                dangerouslySetInnerHTML={{ __html: dealValue }}
              />
            </Box>
          </Grid>
          <Grid item className="bookbtn" xs={6}>
            <NavigatorLink href={url}>
              <Button variant="contained" color="primary">
                {t('fe_er_deal_card:book_now')}
              </Button>
            </NavigatorLink>
          </Grid>
        </Grid>
      </Box>
    </StyledNavigatorLink>
  );
};
export { DealCard };
