import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import Typography500 from 'views/common/components/UI/Typography500';

const StyledNavigatorLink = styled(NavigatorLink)(({ theme }) => ({
  '&.LinkCardNavigator': {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  '& .LinkCardBox': {
    width: '100%',
    height: '100%',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    transition: 'all 0.25s',
    '&.contained, &:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.fogWhite,
    },
  },

  '& .LinkCardColor': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.fogWhite,
  },

  '& .LinkCardDisable': {
    backgroundColor: theme.palette.grey['50'],
    color: theme.palette.grey['50'],
    border: `1px solid ${theme.palette.grey['50']}`,
  },
}));

const LinkCard = (props) => {
  const {
    title, subtitle, url, variant,
  } = props;
  return (
    <>
      <StyledNavigatorLink
        href={url}
        translate={url.translate}
        className="LinkCardNavigator">
        <Box className={`LinkCardBox ${variant}`}>
          <Typography500 variant="h5" component="p">
            {title}
          </Typography500>
          {subtitle && <Typography variant="body2">{subtitle}</Typography>}
        </Box>
      </StyledNavigatorLink>
    </>
  );
};
export { LinkCard };
