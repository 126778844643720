import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.spacing(1),
  width: theme.typography.pxToRem(880),
  margin: 'auto',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(600),
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(360),
  },

  '& .ORPBGridContainer': {
    height: '100%',
  },
  '& .ORPBImgContainer': {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(205),
      '&.showTABLET': {
        display: 'none',
      },
    },
  },
  '& .ORPBTextContainer': {
    position: 'relative',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'baseline',
    padding: theme.spacing(3, 4, 4, 3.5),
    color: theme.palette.common.fogWhite,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3, 2),
      minHeight: theme.typography.pxToRem(200),
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      padding: theme.spacing(2, 2, 3, 2),
      minHeight: 'unset',
    },
  },
  '& .ORPBTitle': {
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.pxToRem(32),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(22),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  '& .ORPBSubTitle': {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(28),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(23),
    },
  },
  '& .ORPBBtn': {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  '& .ORPBBGRightImg': {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '& .IBimg': {
    objectPosition: 'right',
    [theme.breakpoints.down('md')]: {
      objectPosition: 'bottom',
    },
  },
}));

export default StyledBox;
