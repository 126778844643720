import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Typography, Divider, Button,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Typography500 from 'views/common/components/UI/Typography500';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.mp-memberBox': {
    backgroundColor: theme.palette.primary.A000,
    padding: theme.spacing(1, 2, 2),
    position: 'relative',
    overflow: 'hidden',
  },
  '& .mp-memberPricesBox': {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1),
  },
  '& .mp-originalMemberPrice': {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '500',
    lineHeight: theme.spacing(3.25),
    marginRight: theme.spacing(0.5),
  },
  '& .mp-newMemberPrice': {
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '400',
    lineHeight: theme.spacing(3.25),
    textDecoration: 'line-through',
  },
  '& .mp-memberPricesDivider': {
    margin: theme.spacing(1),
  },
  '& .mp-divider': {
    backgroundColor: theme.palette.grey[100],
  },
  '& .mp-totalSavings': {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '400',
    lineHeight: theme.spacing(2),
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
  },
  '& .mp-savingsDiscount': {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '700',
    lineHeight: theme.spacing(2),
    color: theme.palette.success.main,
  },
  '& .mp-onlyLeftText': {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '500',
    lineHeight: theme.spacing(2),
    display: 'flex',
    margin: theme.spacing(0.5, 0, 1.5),
  },
  '& .mp-onlyLeftIcon': {
    color: theme.palette.error.main,
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
    marginRight: theme.spacing(0.5),
  },
  '& .mp-ribbon': {
    background: theme.palette.primary.dark,
    color: theme.palette.common.fogWhite,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(-1.5),
    padding: theme.spacing(0, 2),
    fontSize: theme.typography.pxToRem(12),
    transform: 'rotate(45deg)',
    fontStyle: 'italic',
  },
}));

const MemberPrices = (props) => {
  const { price, discountPercentage } = props;
  const { t } = useTranslation('fe_er_common_rental_card');

  return (
    <StyledBox container className="mp-memberBox">
      <Box className="mp-ribbon">{t('fe_er_common_rental_card:club')}</Box>
      <Box className="mp-memberPricesBox">
        <Box className="mp-memberPriceBox">
          <Typography component="span" className="mp-originalMemberPrice">
            {price}
          </Typography>
          <Typography component="span" className="mp-newMemberPrice">
            {price}
          </Typography>
          <Typography className="mp-smallText mp-grey300" component="p">
            {t('fe_er_common_rental_card:perday')}
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className="mp-divider mp-memberPricesDivider"
        />
        <Box className="mp-memberPriceBox">
          <Typography component="span" className="mp-originalMemberPrice">
            {price}
          </Typography>
          <Typography component="span" className="mp-newMemberPrice">
            {price}
          </Typography>
          <Typography className="mp-smallText mp-grey300" component="p">
            {t('fe_er_common_rental_card:perday')}
          </Typography>
        </Box>
      </Box>
      <Divider className="mp-divider" sx={{ mb: 1 }} />
      <Typography component="p">
        <span className="mp-totalSavings">
          {t('fe_er_common_rental_card:total_savings')}
        </span>
        <Typography500 component="span" className="mp-savingsDiscount">
          {`${price} (${discountPercentage} ${t(
            'fe_er_common_rental_card:off',
          )})`}
        </Typography500>
      </Typography>
      <Typography component="p" className="mp-onlyLeftText">
        <LocalFireDepartmentIcon className="mp-onlyLeftIcon" />
        {t('fe_er_common_rental_card:only_left')}
      </Typography>
      <Button
        fullWidth
        color="primary"
        className="mp-reserveBtn"
        variant="contained">
        {t('fe_er_common_rental_card:reserve_now')}
      </Button>
    </StyledBox>
  );
};

export { MemberPrices };
