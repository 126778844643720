import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import Carousel from 'react-multi-carousel';

export const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  boxShadow: '0px 0px 4px rgb(0 0 0 / 16%)',
  marginTop: '2px',
  marginLeft: '2px',
  '& .TCtourCard': {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  '& .TCtourImgBox': {
    position: 'relative',
    height: theme.spacing(25),
    '& .TCcaruselItem': {
      height: theme.spacing(25),
    },
  },

  '& .TCtourImg': {
    height: '100%',
    minWidth: '100%',
    width: 'auto',
  },

  '& .TCtourDetails': {
    padding: theme.spacing(1),
    height: 'calc(100% - 200px)', // this hight work with  pages view
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .TCTourType': {
      color: theme.palette.grey['400'],
    },
  },

  '& .TCtourInfo': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    color: theme.palette.secondary.light,
    minHeight: theme.spacing(7.125),
  },

  '& .TCtourLocationDays': {
    '& svg': {
      verticalAlign: 'middle',
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    '& p': {
      color: theme.palette.secondary.light,
      lineHeight: theme.spacing(1.75),
    },
    '& p:first-child': {
      marginBottom: theme.spacing(1),
    },
  },
  '& .TCPrice': {
    color: theme.palette.secondary.main,
  },
  '& .TCligthFontWeight': {
    fontWeight: 400,
  },

  '&.TCcardBtn': {
    display: 'block',
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  '& .TCSavedValue': {
    color: theme.palette.secondary.main,
    lineHeight: theme.spacing(2),
    fontWeight: 600,
  },
}));

export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  '& .react-multiple-carousel__arrow': {
    backgroundColor: 'rgba(255,255,255,0.77) !important',
    minHeight: 'unset',
    minWidth: 'unset',
    width: theme.spacing(4),
    height: theme.spacing(4),
    '&.react-multiple-carousel__arrow--right': {
      right: theme.spacing(1.5),
    },
    '&.react-multiple-carousel__arrow--left': {
      left: theme.spacing(1.5),
    },
    '&::before': {
      color: theme.palette.secondary.main,
      fontSize: theme.spacing(1.5),
      padding: theme.spacing(1),
      fontWeight: 700,
    },
  },
}));
